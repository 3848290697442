<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="mt-4">
      <div class="px-4 py-4 mt-5 box">
        <h4>Did you forget your password?</h4>
        <p class="mb-5 mt-3">
          Enter the email address you are using for this account below, and we
          will send you a password reset link.
        </p>

        <div class="mb-3">
          <label for="email">
            <span class="text-danger">*</span>
            Email Address
          </label>
          <input
            autofocus
            id="email"
            v-model="form.email"
            class="form-control"
            type="email"
            placeholder="emailaddy@gmail.com"
          />
        </div>

        <button
          :disabled="!resetReady || resetting"
          @click="resetPassword"
          class="btn mt-4 w-100"
        >
          <span>Request Reset Link</span>
          <span
            v-if="resetting"
            class="spinner-border spinner-border-sm text-light ms-3"
            role="status"
          ></span>
        </button>

        <p class="mt-4 text-center">
          <span class="text-faint">Back to </span>
          <router-link to="/signin"> Sign in</router-link>
        </p>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";
import { guestAxios } from "../services/AxiosInstance";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ForgotPassword",

  components: {
    Header,
    Footer,
  },

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      await executeRecaptcha("login");

      // Do stuff with the received token.
    };

    return {
      recaptcha,
    };
  },

  data() {
    return {
      scrollHeight: 0,
      form: {
        email: null,
      },
      resetting: false,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },

  computed: {
    resetReady() {
      return !!this.form.email;
    },
  },

  methods: {
    async resetPassword() {
      if (!this.resetReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fields",
        });
        return;
      }

      try {
        this.resetting = true;
        const body = {
          ...this.form,
          verifyUrl: window.location.origin + "/reset-password",
        };

        const api = "auth/forgot-password";
        const res = await guestAxios.post(api, body);

        this.resetting = false;
        if (!res) {
          return;
        }

        const { data } = res;

        // clear the form
        this.form = {
          email: null,
        };

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push("/signin");
      } catch (err) {
        console.log(err);
        this.resetting = false;
      }
    },
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  opacity: 0.03;
}
h4 {
  color: #03435f;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.box {
  z-index: 10;
  width: 85%;
  margin: auto;
  max-width: 567px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
p {
  font-size: 14px;
  line-height: 16px;
  color: #6c6c64;
}
label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
}
input {
  height: 55px;
  color: #0b2238;
  background: #ffffff;
}
input:hover,
input:focus {
  box-shadow: none !important;
  border-color: #165df5;
}
input::placeholder {
  color: #6c7a87 !important;
}
button {
  background: rgba(3, 67, 95, 1);
  color: #ffffff;
  padding: 1rem;
}
button:hover {
  color: #ffffff;
}
.fs-7 {
  font-size: 13px !important;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    margin-top: 3rem !important;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
