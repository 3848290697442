<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section
      id="moreInfo"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <p
        v-if="!verifyError && !verified"
        class="text-center fw-bold text-secondary"
      >
        <span class="spinner-border" style="width: 4rem; height: 4rem"></span>
        <br />
        <span>Verifying ...</span>
      </p>

      <p
        v-if="!verifyError && verified"
        class="text-center fw-bold text-success"
      >
        <i class="fas fa-check fa-4x mb-3"></i>
        <br />
        <span>Verified</span>
      </p>

      <p v-if="verifyError" class="text-center fw-bold text-secondary">
        <i class="fas fa-unlink fa-3x mb-3"></i>
        <br />
        <span>Verification link is invalid</span>
      </p>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import { guestAxios } from "../services/AxiosInstance";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Verify",

  components: {
    Header,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
      verifyError: false,
      verified: false,
    };
  },

  mounted() {
    if (!this.$route.params.token) {
      this.$router.push({ path: "/signin" });
    }

    const token = this.$route.params.token;

    const api = "auth/verify";

    // verify token
    guestAxios
      .post(api, {
        token: token,
      })
      .then((res) => {
        // return;
        if (res.response && res.response.status == 404) {
          // show the error message
          this.verifyError = true;
        } else {
          this.verified = true;
          this.$swal({
            icon: "success",
            text: "Your account have been successfully verified!",
            allowOutsideClick: false,
            confirmButtonText: "Proceed",
          }).then((res) => {
            if (res.isConfirmed) {
              this.$router.push({ path: "/signin" });
              return;
            }
          });
        }
      });

    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}
.text-faint {
  color: #6c7a87 !important;
}
.fs-7 {
  font-size: 13px !important;
}
#moreInfo {
  height: 60vh;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
