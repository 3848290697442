<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="">
      <div class="px-3 mt-5">
        <div>
          <h1 class="mt-4">Create an account</h1>
          <p class="mb-5">
            <span class="text-faint">Already have an account? </span>
            <router-link to="/signin">Sign in</router-link>
          </p>
        </div>

        <div
          class="d-block d-lg-flex justify-content-between px-1 px-lg-3 my-5"
        >
          <div class="col-12 col-lg-5 mt-5 mt-lg-0 mx-auto">
            <div class="card shadow card-text border-0 mt-4">
              <div class="card-body d-flex pt-4">
                <img
                  src="@/assets/images/check.svg"
                  alt="icons"
                  width="16"
                  height="16"
                  class="me-3 mt-2"
                />
                <p>
                  Start receiving payments with developer-friendly APIs or
                  choose single string low-code or choose pre-built solutions
                  from our store.
                </p>
              </div>
            </div>

            <div class="card shadow card-text border-0 mt-4">
              <div class="card-body d-flex pt-4">
                <img
                  src="@/assets/images/check.svg"
                  alt="icons"
                  width="16"
                  height="16"
                  class="me-3 mt-2"
                />
                <p>
                  Account Supports any business model including individual
                  payments and receivables.
                </p>
              </div>
            </div>

            <div class="card shadow card-text border-0 mt-4">
              <div class="card-body d-flex pt-4">
                <img
                  src="@/assets/images/check.svg"
                  alt="icons"
                  width="16"
                  height="16"
                  class="me-3 mt-2"
                />
                <p>
                  Online sales, POS, Agent banking, E-commerce, subscriptions,
                  SaaS platforms, marketplaces, and more—all with one account.
                </p>
              </div>
            </div>

            <div class="mt-4">
              <p>
                Join thousands of businesses and individuals both startups,
                enterprises and corporates that trust Appmart.
              </p>
            </div>
          </div>

          <div class="col-12 col-lg-5 mx-auto d-flex justify-content-center">
            <div class="col-12 col-lg-10 card shadow border-0 mt-4 pb-4">
              <div class="card-body pt-4 px-5">
                <h1 class="mt-4">Create an account</h1>
                <p class="mb-5 text-faint">
                  Sign up to create an Appmart account
                </p>

                <div>
                  <div>
                    <label for="email">
                      <span class="text-danger">*</span>
                      Email Address
                    </label>
                    <input
                      id="email"
                      v-model="form.email"
                      class="form-control"
                      type="email"
                      placeholder="email address"
                    />
                    <small v-if="!isEmailValid" class="text-danger fw-bold">
                      Invalid Email, please enter a valid email address!
                    </small>
                  </div>

                  <div class="mt-4">
                    <label for="firstName">
                      <span class="text-danger">*</span>
                      First Name
                    </label>
                    <input
                      id="firstName"
                      v-model="form.firstName"
                      class="form-control"
                      type="text"
                      placeholder="first name"
                    />
                  </div>

                  <div class="mt-4">
                    <label for="lastName">
                      <span class="text-danger">*</span>
                      Last Name
                    </label>
                    <input
                      autocomplete="off"
                      id="lastName"
                      v-model="form.lastName"
                      class="form-control"
                      type="text"
                      placeholder="last name"
                    />
                  </div>

                  <div class="mt-4">
                    <label for="password">
                      <span class="text-danger">*</span>
                      Password
                    </label>
                    <input
                      autocomplete="off"
                      id="password"
                      v-model="form.password"
                      class="form-control"
                      type="password"
                      placeholder="+6 Characters"
                    />
                  </div>

                  <div class="mt-4">
                    <label for="cpassword">
                      <span class="text-danger">*</span>
                      Confirm Password
                    </label>
                    <input
                      autocomplete="off"
                      id="cpassword"
                      v-model="form.cpassword"
                      class="form-control"
                      type="password"
                      placeholder="+6 Characters"
                    />
                  </div>
                  <small v-if="passwordNotMatch" class="text-danger">
                    Passwords does not match
                  </small>

                  <p class="mt-3 fs-7">
                    <span>
                      By clicking on the Sign Up button, you agree to Appmart
                    </span>
                    <router-link to="#/termsofuse">
                      terms and conditions of use.
                    </router-link>
                  </p>

                  <button
                    :disabled="!createReady || creating"
                    @click="createAccount"
                    class="btn mt-4 w-100"
                  >
                    <span>Create account</span>
                    <span
                      v-if="creating"
                      class="spinner-border spinner-border-sm text-light ms-3"
                      role="status"
                    ></span>
                  </button>

                  <p class="mt-4 text-center">
                    <span class="text-faint">Already have an account? </span>
                    <router-link to="/signin">Sign in</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { useReCaptcha } from "vue-recaptcha-v3";
import { mapActions } from "vuex";
import { guestAxios } from "../services/AxiosInstance";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "SignUp",

  components: {
    Header,
    Footer,
  },

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      await executeRecaptcha("login");

      // Do stuff with the received token.
    };

    return {
      recaptcha,
    };

    // template: '<button @click="recaptcha">Execute recaptcha</button>',
  },

  data() {
    return {
      scrollHeight: 0,
      form: {
        email: null,
        firstName: null,
        lastName: null,
        password: null,
        cpassword: null,
      },
      creating: false,
      isEmailValid: true,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },

  computed: {
    createReady() {
      return (
        this.form.email &&
        this.form.firstName &&
        this.form.lastName &&
        this.form.password &&
        this.form.cpassword &&
        this.passwordReady
      );
    },

    passwordReady() {
      return (
        this.form.password &&
        this.form.password.length >= 6 &&
        !this.passwordNotMatch
      );
    },

    passwordNotMatch() {
      return this.form.password && this.form.password != this.form.cpassword;
    },
  },

  methods: {
    ...mapActions(["saveUserInfo"]),

    async validateEmail() {
      if (!this.form.email) {
        return;
      }

      try {
        const data = JSON.stringify({
          email: this.form.email,
          vendor_code: process.env.VUE_APP_vendorCode,
        });

        const config = {
          method: "post",
          url: process.env.VUE_APP_ApiUrl,
          headers: {
            apikey: process.env.VUE_APP_emailApiKey,
            "Content-Type": "application/json",
          },
          data: data,
        };

        const response = await axios(config);

        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async createAccount() {
      try {
        this.creating = true;
        const body = {
          ...this.form,
          verifyUrl: window.location.origin + "/verify",
        };

        const api = "auth/register";
        const res = await guestAxios.post(api, body);

        this.creating = false;
        if (!res) {
          return;
        }

        const { data } = res;

        // clear the form
        this.form = {
          email: null,
          firstName: null,
          lastName: null,
          password: null,
          cpassword: null,
        };

        this.$swal({
          icon: "success",
          text: data.message,
        });
      } catch (err) {
        console.log(err);
        this.creating = false;
      }
    },
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.03;
}
.card-text {
  z-index: -1;
}
.card {
  min-height: 88px !important;
}
.card-body p {
  font-size: 1rem;
  color: #0b2238;
  font-weight: 400;
  line-height: 1.5;
}
input {
  height: 55px;
  color: #0b2238;
  background: #ffffff;
}
input:hover,
input:focus {
  box-shadow: none !important;
  border-color: #165df5;
}
input::placeholder {
  color: #6c7a87 !important;
}
button {
  background: #ed342b;
  color: #ffffff;
  padding: 1rem;
}
button:hover {
  color: #ffffff;
}
.text-faint {
  color: #6c7a87 !important;
}
.fs-7 {
  font-size: 13px !important;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
