<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="mt-4">
      <div v-if="loading" class="px-4 py-4 mt-5 text-center">
        <span
          class="spinner-border spinner-border-xl text-secondary ms-3"
        ></span>
        <p class="mb-3 mt-3">Verifying...</p>
      </div>

      <div v-if="!loading">
        <div
          v-if="resetError"
          class="px-4 py-4 mt-5 box d-flex justify-content-center"
        >
          <div class="col-md-3">
            <i class="fas fa-unlink text-secondary fa-3x mt-2"></i>
          </div>
          <div class="col-md-8">
            <h4 class="">Error Resetting Password</h4>
            <p class="mb-3 mt-3 text-danger fs-6 fw-bold">
              Link is broken or invalid!
            </p>
            <p class="mt-5">
              Click
              <router-link to="/forgot-password"> Here</router-link>
              to reset password again
            </p>
          </div>
        </div>

        <div v-if="!resetSuccess && !resetError" class="px-4 py-4 mt-5 box">
          <h4 class="mb-5 mt-3">Reset your password</h4>

          <div class="mt-4">
            <label for="password">
              <span class="text-danger">*</span>
              New Password
            </label>
            <input
              autocomplete="off"
              id="password"
              v-model="form.password"
              class="form-control"
              type="password"
              placeholder="+6 Characters"
            />
          </div>

          <div class="mt-4">
            <label for="cpassword">
              <span class="text-danger">*</span>
              Re-enter New Password
            </label>
            <input
              autocomplete="off"
              id="cpassword"
              v-model="form.cpassword"
              class="form-control"
              type="password"
              placeholder="+6 Characters"
            />
          </div>
          <small v-if="passwordNotMatch" class="text-danger">
            Passwords does not match
          </small>

          <button
            :disabled="!resetReady || resetting"
            @click="resetPassword"
            class="btn mt-4 w-100"
          >
            <span>Reset my password</span>
            <span
              v-if="resetting"
              class="spinner-border spinner-border-sm text-light ms-3"
              role="status"
            ></span>
          </button>
        </div>

        <div v-if="resetSuccess" class="px-4 py-4 mt-5 box">
          <h4>Password Reset Successful!</h4>
          <p class="mb-3 mt-3">
            You can now use your new password to log in to your account!
          </p>

          <router-link to="/signin" class="btn a-btn mt-4 w-100">
            Login
          </router-link>
        </div>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";
import { guestAxios } from "../services/AxiosInstance";
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ForgotPassword",

  components: {
    Header,
    Footer,
  },

  props: ["token", "email"],

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      await executeRecaptcha("login");

      // Do stuff with the received token.
    };

    return {
      recaptcha,
    };
  },

  data() {
    return {
      scrollHeight: 0,
      form: {
        password: null,
        cpassword: null,
      },
      loading: true,
      resetSuccess: false,
      resetting: false,
      resetError: false,
    };
  },

  created() {
    const endPoint = "auth/verify-reset";
    guestAxios
      .post(endPoint, {
        email: this.email,
        token: this.token,
      })
      .then((res) => {
        this.loading = false;

        if (!res) {
          this.resetError = true;
        }
      })
      .catch((err) => {
        console.log({ err });
        this.loading = false;
      });
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },

  computed: {
    resetReady() {
      return this.form.password && this.form.cpassword && this.passwordReady;
    },

    passwordReady() {
      return (
        this.form.password &&
        this.form.password.length >= 6 &&
        !this.passwordNotMatch
      );
    },

    passwordNotMatch() {
      return this.form.password && this.form.password != this.form.cpassword;
    },
  },

  methods: {
    async resetPassword() {
      if (!this.resetReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fields",
        });
        return;
      }

      try {
        this.resetting = true;
        const body = {
          ...this.form,
          email: this.email,
          token: this.token,
        };

        const api = "auth/reset-password";
        const res = await guestAxios.post(api, body);

        this.resetting = false;
        if (!res) {
          return;
        }

        const { data } = res;

        // clear the form
        this.form = {
          password: null,
          cpassword: null,
        };

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.resetSuccess = true;
      } catch (err) {
        console.log(err);
        this.resetting = false;
      }
    },
  },
};
</script>
<style scoped>
.about {
  height: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  opacity: 0.03;
}
h4 {
  color: #03435f;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
.box {
  z-index: 10;
  width: 85%;
  margin: auto;
  max-width: 567px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
p {
  font-size: 14px;
  line-height: 16px;
  color: #6c6c64;
}
label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
}
input {
  height: 55px;
  color: #0b2238;
  background: #ffffff;
}
input:hover,
input:focus {
  box-shadow: none !important;
  border-color: #165df5;
}
input::placeholder {
  color: #6c7a87 !important;
}
.spinner-border-xl {
  width: 4rem;
  height: 4rem;
}
button,
.a-btn {
  background: rgba(3, 67, 95, 1);
  color: #ffffff;
  padding: 1rem;
}
button:hover,
.a-btn:hover {
  color: #ffffff;
}
.fs-7 {
  font-size: 13px !important;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    height: 50vh;
    margin-top: 7rem !important;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
